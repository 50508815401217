.step-form-alignment {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .top-form-alignment {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;

        .step-input-alignment {
            &.last-step-aligment {
                padding: 0 0 26px 0;
            }

            .input-details {
                padding: 16px 0 0 0;
                width: 100%;

                .radio-btn-group {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                    flex-direction: row;
                }


                textarea {
                    width: 100%;
                    height: 65px;
                    background: #f4f4f4;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    border-radius: 6px;
                    border: none;
                    resize: none;
                    padding: 16px;

                    &:focus {
                        outline: none;
                    }
                }

                select {
                    width: 100%;
                    height: 38px;
                    background: #f4f4f4;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    border-radius: 6px;
                    border: none;
                    resize: none;
                    padding: 0 20px;
                    font-family: Verdana, sans-serif;

                    &:focus {
                        outline: none;
                    }
                }

                input {
                    width: 100%;
                    height: 38px;
                    background: #f4f4f4;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    border-radius: 6px;
                    border: none;
                    resize: none;
                    font-family: Verdana, sans-serif;

                    &:focus {
                        outline: none;
                    }
                }

                .recommendation-wrapper {
                    width: 100%;
                    background: #c7c1fb;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    border-radius: 6px;
                    border: none;
                    resize: none;
                    font-family: Verdana, sans-serif;
                    font-weight: 600;
                    display: block;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    span {
                        margin-left: 20px;
                    }
                }

                .input-mask-wrapper {
                    position: relative;
                    width: 100%;

                    button {
                        position: absolute;
                        top: 0;
                        right: -2px;
                        height: 100%;
                        border: none;
                        padding: 14px;
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }
                }
            }

            .calculate-button-alignment {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .calculate-button {
                    background: #393184;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                    border: none;
                    padding: 12px;
                    font-size: 16px;
                }
            }
        }

        .step-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 26px;
        }

    }
}