.main-home-wrapper {
    position: relative;


    @media (max-width: 1024px) {
        height: auto;
    }

    .home-page-content-wrapper {
        .home-section-title {
            color: #393184;
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            padding: 0 0 35px 0;

            @media (max-width: 1023px) {
                font-size: 22px;
                line-height: 28px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 24px;
                padding: 0 0 20px 0;
            }
        }

        .home-banner-wrapper {
            overflow: hidden;
            position: relative;

            .banner-content-wrapper {
                height: 672px;
                position: relative;

                @media (max-width: 767px) {
                    height: 100%;
                }

                @media (max-width: 540px) {
                    padding: 40px 0;
                }

                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;

                    @media (max-width: 540px) {
                        display: none;
                    }
                }

                .banner-text-wrapper {
                    position: absolute;
                    top: 90px;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 38px;
                    flex-direction: column;
                    max-width: 600px;
                    width: 100%;
                    transform: translateX(-50%);
                }

                .left-side-text-wrapper {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 83px;

                    @media (max-width: 767px) {
                        left: 24px;
                    }

                    @media (max-width: 540px) {
                        position: static;
                        transform: unset;
                        padding: 0 20px;
                    }
                }

                .mobile-view-banner-alignment {
                    display: none;

                    @media (max-width: 540px) {
                        display: block;

                        &.first-banner-mobile {
                            margin: 40px 0 0 0;
                            height: 380px;

                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }

                        &.second-banner-mobile {
                            margin: 40px 0 0 0;
                            height: 280px;

                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }

                .text-details-alignment {
                    h1 {
                        font-size: 50px;
                        font-weight: 400;
                        line-height: 70px;
                        letter-spacing: -0.011em;
                        text-align: left;
                        max-width: 690px;
                        color: #000000cc;

                        @media (max-width: 1023px) {
                            font-size: 36px;
                            line-height: 42px;
                        }

                        @media (max-width: 767px) {
                            font-size: 24px;
                            line-height: 28px;
                        }
                    }

                    p {
                        padding: 22px 0 0 0;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 26.84px;
                        letter-spacing: -0.011em;
                        text-align: left;
                        color: #000000cc;

                        @media (max-width: 767px) {
                            max-width: 410px;
                        }

                        @media (max-width: 540px) {
                            max-width: 100%;
                        }
                    }

                    .welcome-text {
                        color: #393184;
                        font-weight: 400;
                        font-size: 70px;
                        line-height: 80px;
                        text-align: left;
                        line-height: 80.5px;
                        letter-spacing: -0.011em;
                        text-align: left;
                        max-width: 640px;
                    }

                    .banner3-text {
                        font-size: 50px;
                        font-weight: 400;
                        line-height: 70px;
                        letter-spacing: -0.011em;
                        text-align: left;
                        max-width: 690px;
                        color: #393184;
                    }
                }

                .button-alignment {
                    padding: 60px 0 0 0;

                    @media (max-width: 767px) {
                        padding: 40px 0 0 0;
                    }

                    button {
                        background: #393184;
                        padding: 12px 24px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17.01px;
                        color: #ffffff;
                        cursor: pointer;
                        border: none;
                        position: relative;
                        overflow: hidden;

                        &::after {
                            content: "";
                            position: absolute;
                            top: -50%;
                            right: -50%;
                            bottom: -50%;
                            left: -80%;
                            background: linear-gradient(to bottom,
                                    rgba(229, 172, 142, 0),
                                    rgba(255, 255, 255, 0.5) 50%,
                                    rgba(229, 172, 142, 0));
                            transform: rotateZ(60deg) translate(-5em, 7.5em);
                        }

                        /*This activates the sheen once the button has been hovered*/
                        &:hover::after,
                        button:focus::after {
                            animation: sheen 1s forwards;
                        }

                        @keyframes sheen {
                            100% {
                                transform: rotateZ(60deg) translate(1em, -12em);
                            }
                        }
                    }
                }

                .second-banner-alignment {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: center;
                    gap: 40px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50px;

                    @media (max-width: 1023px) {
                        grid-template-columns: 1fr;
                    }

                    @media (max-width: 540px) {
                        position: static;
                        padding: 0 20px;
                        transform: unset;
                    }
                }

                .banner-header-text {
                    width: 710px;
                    max-width: 710px;

                    @media (max-width: 767px) {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                .heading-max-alignment {
                    max-width: 710px;

                    @media (max-width: 767px) {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                .right-img-alignment {
                    width: 100%;
                    height: 400px;

                    @media (max-width: 1023px) {
                        height: 250px;
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;

                        @media (max-width: 1023px) {
                            object-fit: contain;
                        }
                    }
                }
            }

            .cloud-img-alignment {
                -webkit-animation: animateCloud 40s linear infinite;
                -moz-animation: animateCloud 40s linear infinite;
                animation: animateCloud 40s linear infinite;

                -webkit-transform: scale(0.65);
                -moz-transform: scale(0.65);
                transform: scale(0.65);
                position: absolute;
                top: 0px;
                left: 10px;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .cloud-img-alignment2 {
                -webkit-animation: animateCloud 50s linear infinite;
                -moz-animation: animateCloud 50s linear infinite;
                animation: animateCloud 50s linear infinite;

                -webkit-transform: scale(0.3);
                -moz-transform: scale(0.3);
                transform: scale(0.3);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .cloud-img-alignment3 {
                -webkit-animation: animateCloud 30s linear infinite;
                -moz-animation: animateCloud 30s linear infinite;
                animation: 30s animateCloud 0.4s linear infinite;

                -webkit-transform: scale(0.5);
                -moz-transform: scale(0.5);
                transform: scale(0.5);
                position: absolute;
                bottom: -120px;
                left: 10px;

                @media (max-width: 767px) {
                    display: none;
                }
            }
        }

        .our-milestone-wrapper {
            // padding: 118px 0 0 0;

            @media (max-width: 1279px) {
                padding: 90px 0 0 0;
            }

            @media (max-width: 1023px) {
                padding: 60px 0 0 0;
            }

            @media (max-width: 767px) {
                padding: 40px 0 0 0;
            }

            .our-milestone-grid-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 95px;
                flex-wrap: wrap;

                @media (max-width: 1023px) {
                    gap: 40px;
                }

                .our-milestone-grid-item {
                    text-align: center;

                    @media (max-width: 767px) {
                        .our-milestone-icon {
                            width: 80px;
                            height: 80px;
                            margin: 0 auto;

                            img {
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }
                    }

                    .milestone-title {
                        color: #393184;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: center;
                        padding: 16px 0 0 0;

                        @media (max-width: 1023px) {
                            font-size: 18px;
                            line-height: 21px;
                            padding: 12px 0 0 0;
                        }
                    }

                    .milestone-desc {
                        color: #a5a5a5;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        text-align: center;
                        padding: 10px 0 0 0;

                        @media (max-width: 1023px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .our-projects-wrapper {
            padding: 118px 0 0 0;
            margin: 0 auto;

            @media (max-width: "767px") {
                padding: 70px 0 0 0;
            }

            .projects-grid-wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 15px;

                @media (max-width: 1023px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .project-grid-item {
                    background-color: #eceaff;
                    height: 370px;

                    @media (max-width: 1279px) {
                        height: 280px;
                    }

                    .bg-1 {
                        height: 100%;
                        width: 100%;
                        background-image: url("../../assets/images/Happy Celebrity Green - Surat.jpeg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% 100%;
                    }

                    .bg-2 {
                        height: 100%;
                        width: 100%;
                        background-image: url("../../assets/images/KIA Showroom-Surat.avif");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% 100%;
                    }

                    .bg-3 {
                        height: 100%;
                        width: 100%;
                        background-image: url("../../assets/images/Sangini Corporate House - Surat.jpg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% 100%;
                    }

                    .bg-4 {
                        height: 100%;
                        width: 100%;
                        background-image: url("../../assets/images/Rajhans Belliza - Surat.jpg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% 100%;
                    }

                    .project-title-wrapper {
                        height: 100%;
                        width: 100%;
                        font-size: 22px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #0000006b;
                        color: #fff;
                        cursor: pointer;

                        p {
                            color: #fff;
                        }
                    }
                }
            }

            .project-details-wrapper {
                display: grid;
                grid-template-columns: 470px 1fr;
                gap: 44px;
                background-color: #eceaff;
                padding: 24px;
                margin: 35px 0 0 0;

                .project-image-wrapper {
                    width: 470px;
                    height: 100%;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .project-details-section {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 8px;
                    flex-direction: column;

                    .project-detail-name {
                        font-size: 25px;
                        font-weight: 700;
                        line-height: 33px;
                        color: #393184;
                        cursor: pointer;
                    }

                    .project-detail-location {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        color: #7a70da;
                        cursor: pointer;
                    }
                }
            }
        }

        .about-us-wrapper {
            padding: 118px 0 0 0;
            max-width: 1120px;
            margin: 0 auto;

            @media (max-width: 1279px) {
                padding: 90px 0 0 0;
            }

            .about-us-content-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 90px;

                @media (max-width: 1279px) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }

                @media (max-width: 767px) {
                    gap: 50px;
                }

                .about-us-details {
                    max-width: 515px;

                    @media (max-width: 1279px) {
                        max-width: 100%;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #7a7a7a;
                        padding: 0 0 16px 0;

                        @media (max-width: "991px") {
                            text-align: center;
                            font-size: 16px;
                            line-height: 21px;
                        }

                        @media (max-width: "767px") {
                            text-align: center;
                            font-size: 14px;
                            line-height: 21px;
                        }

                        &:last-child {
                            padding: 0;
                        }
                    }
                }

                .about-us-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 32px;
                    width: 100%;

                    @media (max-width: "767px") {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .about-us-grid-item {
                        display: grid;
                        grid-template-columns: 52px 1fr;
                        gap: 30px;
                        align-items: center;

                        .content-alignment {
                            .content-title {
                                font-size: 24px;
                                font-weight: 400;
                                line-height: 36px;
                                letter-spacing: -0.011em;
                                color: #393184;

                                @media (max-width: "767px") {
                                    font-size: 20px;
                                }
                            }

                            .content-description {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                letter-spacing: -0.011em;
                                color: #a5a5a5;
                                padding: 10px 0 0 0;

                                @media (max-width: "767px") {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .our-market-wrapper {
            padding: 118px 0 0 0;

            @media (max-width: 1279px) {
                padding: 90px 0 0 0;
            }

            .our-market-grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media (max-width: 1279px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .market-grid-item-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 23px;

                    .market-grid-item {
                        width: 100%;
                        height: 398px;
                        background-color: #eceaff;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                        }
                    }

                    p {
                        font-family: Verdana;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                        letter-spacing: -0.011em;
                        text-align: center;
                        color: #1e1e1e;
                        max-width: 234px;
                        cursor: pointer;
                    }
                }
            }
        }

        .our-alliance-wrapper {
            padding: 90px 0 118px 0;

            @media (max-width: "767px") {
                padding: 90px 0;
            }

            .our-alliance-logo-wrapper {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                gap: 32px;
                padding: 0 0 45px 0;

                &:last-child {
                    padding: 0;
                }

                .logo-wrapper {
                    border: none;
                    border-radius: 10px;
                    // background: #eceaff;
                    background: #dad6ff;
                    width: 184px;
                    min-width: 184px;
                    height: 84px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .slider-alignment {
            .slick-dots {
                display: block;
                bottom: 44px !important;
                left: 0 !important;
                text-align: left !important;
                padding: 0 0 0 86px !important;
                width: calc(100% - 100px);
                position: relative;
                overflow: hidden;
                transition: transform 0.2s;

                @media (max-width: "540px") {
                    bottom: 30px !important;
                    left: -70px !important;
                }
            }

            .slick-dots li button:before {
                font-size: 16px !important;
            }

            .slick-dots li button:after {
                content: unset;
            }

            .slick-dots li button:hover {
                &:hover {
                    transform: scale(1.2);
                }
            }

            .slick-next {
                display: none !important;
            }

            .slick-prev {
                display: none !important;
            }
        }

        .wrapper {
            height: 400vh;
        }

        #v0 {
            bottom: 100px;
            width: 100%;
            z-index: 3;
            height: auto;
        }

        #scrollSection {
            display: block;
        }
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }

    100% {
        margin-left: 100%;
    }
}