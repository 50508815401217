.main-product-wrapper {
  .product-page-content-wrapper {
    .product-banner-wrapper {
      height: calc(100vh - 107px);

      @media (max-width: "1500px") {
        height: calc(100vh - 450px);
      }

      @media (max-width: "1279px") {
        height: calc(100vh - 600px);
      }

      @media (max-width: "991px") {
        height: calc(100vh - 740px);
      }

      @media (max-width: "767px") {
        height: calc(100vh - 190px);
      }

      @media (max-width: "540px") {
        height: calc(100vh - 700px);
      }

      .banner-content-wrapper {
        height: calc(100vh - 107px);
        position: relative;
        width: 100%;

        @media (max-width: "1500px") {
          height: calc(100vh - 450px);
        }

        @media (max-width: "1279px") {
          height: calc(100vh - 600px);
        }

        @media (max-width: "991px") {
          height: calc(100vh - 740px);
        }

        @media (max-width: "767px") {
          height: calc(100vh - 190px);
        }

        @media (max-width: "540px") {
          height: calc(100vh - 700px);
        }

        .banner-video-wrapper {
          width: 100%;

          #productvideo {
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
            z-index: -1;
          }
        }

        .banner-text-wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 38px;
          flex-direction: column;
          width: 100%;
          transform: translate(-50%, -50%);

          .banner-header-text {
            .welcome-text {
              color: #000;
              font-weight: 400;
              font-size: 70px;
              line-height: 80px;
              text-align: center;
              max-width: 860px;

              @media (max-width: "991px") {
                font-size: 50px;
                line-height: 60px;
                max-width: 770px;
              }

              @media (max-width: "767px") {
                font-size: 30px;
                line-height: 40px;
                max-width: 350px;
              }

              @media (max-width: "540px") {
                font-size: 24px;
                line-height: 34px;
                max-width: 424px;
              }
            }

            p {
              color: #000;
              font-size: 20px;
              font-weight: 400;
              line-height: 26.84px;
              text-align: center;
              padding: 21px 0 0 0;

              @media (max-width: "767px") {
                padding: 20px;
              }

              @media (max-width: "540px") {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .product-after-banner-content {
      margin: 80px 0 0 0;

      .product-description {
        text-align: center;

        p {
          text-align: center;
          font-size: 27px;
          font-weight: 500;
          line-height: 30px;
          max-width: 378px;
          margin: 0 auto;
        }

        .product-box-alignment {
          margin: 0 auto 80px auto;
          width: 500px;

          @media (max-width: "1500px") {
            margin: 0 auto 50px auto;
            width: 400px;
          }

          @media (max-width: "1279px") {
            margin: 0 auto 50px auto;
            width: 400px;
          }

          @media (max-width: "991px") {
            margin: 0 auto 40px auto;
            width: 360px;
          }

          @media (max-width: "540px") {
            margin: 0 auto 30px auto;
            width: 250px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .product-details-wrapper {
        text-align: center;
        padding: 80px 0 0 0;

        .product-find-title {
          font-size: 50px;
          line-height: 75px;
          color: #1e1e1e;
          max-width: 600px;
          margin: 0 auto;
          text-align: center;
          letter-spacing: -0.011em;

          @media (max-width: "991px") {
            font-size: 36px;
            line-height: 45px;
          }

          @media (max-width: "767px") {
            font-size: 40px;
            line-height: 55px;
          }

          @media (max-width: "540px") {
            font-size: 30px;
            line-height: 45px;
          }
        }

        .product-images-wrapper {
          padding: 44px 0 0 0;

          .product-image-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;

            @media (max-width: "1279px") {
              grid-template-columns: repeat(2, 1fr);
              gap: 40px;
            }

            @media (max-width: "991px") {
              grid-template-columns: repeat(2, 1fr);
              gap: 30px;
            }

            @media (max-width: "767px") {
              grid-template-columns: repeat(1, 1fr);
              gap: 30px;
            }

            .product-image-grid-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 20px;

              .productImg {
                @media (max-width: "540px") {
                  margin: 0 auto;
                  width: 250px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }
              }

              .productButton {
                height: 44px;

                button {
                  width: 100%;
                  height: 100%;
                  border: none;
                  background: #393184;
                  cursor: pointer;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16.8px;
                  text-align: center;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .product-other-details-section {
      padding: 150px 0 0 0;

      @media (max-width: "991px") {
        padding: 90px 0 0 0;
      }

      @media (max-width: "767px") {
        padding: 70px 0 0 0;
      }

      .product-other-details-alignment {
        padding: 0 0 148px 0;

        @media (max-width: "991px") {
          padding: 0 0 100px 0;
        }

        @media (max-width: "767px") {
          padding: 0 0 50px 0;
        }

        .heading-alignment {
          max-width: 830px;
          margin: 0 auto;

          @media (max-width: "767px") {
            max-width: 230px;
          }

          @media (max-width: "540px") {
            max-width: 370px;
          }

          h2 {
            font-size: 50px;
            font-weight: 400;
            line-height: 75px;
            letter-spacing: -0.011em;
            text-align: center;

            @media (max-width: "767px") {
              font-size: 30px;
              line-height: 45px;
              word-break: break-word;
              white-space: normal;
              word-wrap: break-word;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24.16px;
            letter-spacing: -0.011em;
            text-align: center;
            padding: 30px 0 0 0;
          }

          .enquire-now-button {
            max-width: 194px;
            margin: 30px auto 0 auto;
            height: 44px;

            button {
              width: 100%;
              height: 100%;
              background: #393184;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.8px;
              text-align: center;
              color: #fff;
              cursor: pointer;
              border: none;
            }
          }
        }

        .product-all-content-alignment {
          padding: 70px 0 0 0;
          max-width: 1022px;
          margin: 0 auto;

          .content-flex {
            display: grid;
            grid-template-columns: 298px 1fr;
            gap: 130px;

            @media (max-width: "991px") {
              gap: 18px;
            }

            @media (max-width: "767px") {
              grid-template-columns: repeat(1, 1fr);
              gap: 30px;
            }

            .content-img-box {
              width: 100%;
              height: 378px;

              @media (max-width: "767px") {
                width: 300px;
              }

              @media (max-width: "540px") {
                width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
              }
            }

            .main-view-more-alignment {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: column;
              gap: 20px;

              .content-view-more-alignment {
                max-height: 378px;
                overflow: auto;

                .content-first-child-padding {
                  padding: 42px 10px 0 10px !important;
                }

                .content-right-side-alignment {
                  padding: 42px 10px 0 10px;

                  &:first-child {
                    padding: 0;
                  }

                  h3 {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 33.6px;
                    color: #393184;
                    text-align: center;
                    max-width: 386px;
                    margin: 0 auto;
                  }

                  p {
                    padding: 14px 0 0 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.011em;
                    text-align: center;
                    max-width: 458px;
                    margin: 0 auto;
                    color: #A5A5A5;
                  }

                  .right-content-details-flex {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 40px;
                    padding: 24px 0 0 0;

                    .all-details-content {
                      text-align: center;

                      .content-img {
                        width: 100px;
                        margin: 0 auto;
                        height: 100px;
                        background: #eaf2ff;
                        border-radius: 50%;

                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                          object-fit: cover;
                          border: none;
                        }
                      }

                      p {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24.16px;
                        letter-spacing: -0.011em;
                        color: #393184;
                        padding: 14px 0 0 0;
                      }
                    }
                  }
                }
              }

              button {
                font-family: Verdana;
                margin: 20px 0px;
                padding: 10px 20px;
                background: none;
                border: none;
                font-size: 16px;
                font-weight: 400;
                line-height: 24.16px;
                letter-spacing: -0.011em;
                color: #393184;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                flex-direction: column;
                position: relative;
                overflow: unset;
                cursor: pointer;

                &:focus-visible {
                  outline: none;
                }

                &::after {
                  content: unset;
                }

                .down-arrow {
                  position: absolute;
                  top: 30px;
                  animation: jumpInfinite 3s infinite;
                }

                .down-arrow:after {
                  content: " ";
                  position: absolute;
                  top: 12px;
                  left: -10px;
                  width: 16px;
                  height: 16px;
                  border-bottom: 4px solid;
                  border-right: 4px solid;
                  border-radius: 4px;
                  transform: rotateZ(45deg);
                }

                @keyframes jumpInfinite {
                  0% {
                    margin-top: 0;
                  }

                  50% {
                    margin-top: 10px;
                  }

                  100% {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Animation for the additional content */
.content-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.content-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.content-exit {
  opacity: 1;
  transform: translateY(0);
}

.content-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}