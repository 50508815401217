body {
    margin: 0;
    font-family: Verdana, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1279px;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: 1400px) {
        max-width: 1360px;
    }

    @media (max-width: 1279px) {
        max-width: 1200px;
    }

    /* For screens smaller than 991px */
    @media (max-width: 991px) {
        max-width: 960px;
    }

    /* For screens smaller than 767px */
    @media (max-width: 767px) {
        max-width: 740px;
    }

    /* For screens smaller than 740px */
    @media (max-width: 740px) {
        max-width: 720px;
    }

    /* For screens smaller than 540px */
    @media (max-width: 540px) {
        max-width: 520px;
    }
}

.our-milestone-icon {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -80%;
        background: linear-gradient(to bottom,
                rgba(229, 172, 142, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(229, 172, 142, 0));
        transform: rotateZ(60deg) translate(-5em, 7.5em);
    }

    img {
        /*This activates the sheen once the button has been hovered*/
        cursor: pointer;
    }

    &:hover::after,
    img:focus::after {
        animation: sheen 1s forwards;
    }

    @keyframes sheen {
        100% {
            transform: rotateZ(60deg) translate(1em, -12em);
        }
    }
}

button {
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -80%;
        background: linear-gradient(to bottom,
                rgba(229, 172, 142, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(229, 172, 142, 0));
        transform: rotateZ(60deg) translate(-5em, 7.5em);
    }

    &:hover::after,
    button:focus::after {
        animation: shine 1s forwards;
    }

    @keyframes shine {
        100% {
            transform: rotateZ(60deg) translate(1em, -12em);
        }
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e8e6ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #393184;
}