.calculatorBanner-section {
    width: 100%;

    background: url("../../../assets/images/calcultor-banner-img.png");
    display: flex;
    align-items: center;
    justify-content: center;

    .calculatorBanner-details {
        width: 100%;
        height: calc(100vh - 107px);
        display: grid;
        grid-template-columns: 724px 1fr;
        align-items: center;
        gap: 130px;

        @media (max-width: 1279px) {
            height: calc(100vh - 670px);
            grid-template-columns: 480px 1fr;
            gap: 30px;
        }

        @media (max-width: 991px) {
            height: calc(100vh - 400px);
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
            justify-content: center;
        }

        @media (max-width: 540px) {
            height: calc(100vh - 700px);
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            justify-content: center;
        }

        h1 {
            font-size: 70px;
            font-weight: 400;
            line-height: 80.5px;
            letter-spacing: -0.011em;
            text-align: left;
            color: #000000;

            @media (max-width: 1279px) {
                font-size: 58px;
            }

            @media (max-width: 991px) {
                font-size: 50px;
                line-height: 70px;
                text-align: center;
            }

            @media (max-width: 540px) {
                font-size: 30px;
                line-height: 40px;
                text-align: center;
            }
        }

        .left-side-img {
            width: 100%;
            height: 432px;

            @media (max-width: 1279px) {
                height: 332px;
            }

            @media (max-width: 991px) {
                height: 400px;
                width: 284px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            @media (max-width: 540px) {
                height: 230px;
                width: 284px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}