.main-calculator-page-wrapper {
    .main-calculator-wrapper {
        margin: 80px auto;

        .stepper-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 23px;
            position: relative;

            @media (max-width: 540px) {
                gap: 0;
            }

            .stepname {
                span {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                    color: #c0c0c0;
                    display: block;
                    padding: 0 0 10px 0;
                    text-align: center;
                    text-wrap: nowrap;
                }

                .box-alignment {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #393185;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    cursor: pointer;

                    &.active-box {
                        background: #4de27f;
                    }

                    p {
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 400;
                        color: #fff;
                    }
                }

                h6 {
                    padding: 12px 0 0 0;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #000000;
                    white-space: nowrap;
                }
            }

            .stepper-line {
                width: 20%;
                height: 2px;
                background: #00000080;

                &.greenBar {
                    background: #4de27f;
                }
            }
        }

        .stepper-content {
            display: grid;
            grid-template-columns: 430px 1fr;
            gap: 46px;
            padding: 48px 0 0 0;

            @media (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                padding: 20px 0 0 0;
                gap: 30px;
            }
            @media (max-width: 540px) {
                grid-template-columns: repeat(1, 1fr);
                padding: 20px 0 0 0;
                gap: 30px;
            }

            .glass-output-wrapper {
                background-color: transparent;
                width: 100%;
                height: 430px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;

                @media (max-width: 540px) {
                    height: 350px;
                }

                img {
                    position: absolute;
                    width: 130px;
                    height: 180px;
                }

                .box {
                    height: 250px;
                    width: 170px;
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 12px;
                    display: inline-block;
                    margin: 10px 20px 10px 20px;
                    transition: 0.4s ease-in;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 30px;
                        top: 60px;
                        height: 100%;
                        width: 100%;
                        background: #000;
                    }
                }

                .b1 {
                    transform-origin: left center;
                    transform: perspective(300px) rotatey(45deg);
                    opacity: 0.8;
                }
            }

            .stepper-main-form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;
                flex-direction: column;
                max-height: 430px;
                overflow: auto;
                padding: 0 10px;

                .stepper-form-alignment {
                    width: 100%;

                    label {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 15.37px;
                        text-align: left;
                        color: #000;
                        font-family: Verdana, sans-serif;
                    }

                    .label-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    .glass-input-wrapper {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 10px;

                        .input-group-wrapper {
                            width: 100%;
                            margin: 0 0 20px 0;

                            button {
                                font-size: 18px;
                                font-weight: 500;
                                border: none;
                                width: 34px;
                                height: 32px;
                                border-radius: 50%;
                                background: #393185;
                                color: #fff;
                            }
                        }
                    }

                    .step-form-alignment {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;

                        .top-form-alignment {
                            width: 100%;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            gap: 20px;

                            .step-input-alignment {
                                &.last-step-aligment {
                                    padding: 0 0 26px 0;
                                }

                                .input-details {
                                    padding: 16px 0 0 0;
                                    width: 100%;

                                    .radio-btn-group {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap: 20px;
                                        flex-direction: row;
                                    }


                                    textarea {
                                        width: 100%;
                                        height: 65px;
                                        background: #f4f4f4;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: #000;
                                        border-radius: 6px;
                                        border: none;
                                        resize: none;
                                        padding: 16px;

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    select {
                                        width: 100%;
                                        height: 38px;
                                        background: #f4f4f4;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: #000;
                                        border-radius: 6px;
                                        border: none;
                                        resize: none;
                                        padding: 0 20px;
                                        font-family: Verdana, sans-serif;

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    input {
                                        width: 100%;
                                        height: 38px;
                                        background: #f4f4f4;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: #000;
                                        border-radius: 6px;
                                        border: none;
                                        resize: none;
                                        font-family: Verdana, sans-serif;

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    .recommendation-wrapper {
                                        width: 100%;
                                        background: #c7c1fb;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: #000;
                                        border-radius: 6px;
                                        border: none;
                                        resize: none;
                                        font-family: Verdana, sans-serif;
                                        font-weight: 600;
                                        display: block;
                                        height: 50px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;

                                        span {
                                            margin-left: 20px;
                                        }
                                    }

                                    .input-mask-wrapper {
                                        position: relative;
                                        width: 100%;

                                        button {
                                            position: absolute;
                                            top: 0;
                                            right: -2px;
                                            height: 100%;
                                            border: none;
                                            padding: 14px;
                                            border-top-right-radius: 6px;
                                            border-bottom-right-radius: 6px;
                                        }
                                    }
                                }
                            }

                            .step-grid {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 26px;
                            }

                            .step-grid-2 {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 26px;
                            }

                            .four-grid {
                                display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 30px;
                                align-items: center;
                            }
                        }
                    }
                }

            }
        }
    }

    table {
        tr {
            th {
                font-weight: 600;
            }

            select {
                width: 100%;
                height: 38px;
                background: #f4f4f4;
                font-size: 14px;
                line-height: 21px;
                color: #000;
                border-radius: 6px;
                border: none;
                resize: none;
                padding: 0 20px;
                font-family: Verdana, sans-serif;

                &:focus {
                    outline: none;
                }
            }

            input {
                width: 100%;
                height: 38px;
                background: #f4f4f4;
                font-size: 14px;
                line-height: 21px;
                color: #000;
                border-radius: 6px;
                border: none;
                resize: none;
                font-family: Verdana, sans-serif;

                &:focus {
                    outline: none;
                }
            }

            .spacer-value {
                width: 100%;
                background: #f4f4f4;
                font-size: 14px;
                line-height: 21px;
                color: #000;
                border-radius: 6px;
                border: none;
                resize: none;
                font-family: Verdana, sans-serif;
                display: block;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    margin-left: 20px;
                }
            }
        }
    }
}