.main-project-wrapper {
    .project-content-wrapper {
        .about-section-title {
            color: #393184;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            padding: 0 0 35px 0;
        }

        .project-banner-wrapper {
            .banner-content-wrapper {
                height: 672px;
                position: relative;

                @media (max-width: "991px") {
                    height: 372px;
                }

                @media (max-width: "767px") {
                    height: 372px;
                }

                @media (max-width: "540px") {
                    height: 272px;
                }

                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }

                .banner-text-wrapper {
                    position: absolute;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 38px;
                    flex-direction: column;
                    width: 100%;
                    transform: translate(0, 100%);

                    @media (max-width: "991px") {
                        transform: translate(0, 50%);
                    }

                    .banner-header-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 18px;
                        flex-direction: column;
                        text-align: center;

                        .welcome-text {
                            color: #fff;
                            font-weight: 400;
                            font-size: 70px;
                            line-height: 80px;
                            text-align: center;

                            @media (max-width: "991px") {
                                font-size: 50px;
                                line-height: 60px;
                            }

                            @media (max-width: "767px") {
                                font-size: 30px;
                                line-height: 30px;
                            }

                            @media (max-width: "540px") {
                                font-size: 26px;
                                line-height: 26px;
                            }
                        }

                        p {
                            color: #fff;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24.84px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .project-description-content {
            text-align: center;
            padding: 100px 0 0 0;
            max-width: 650px;
            margin: 0 auto;

            p {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .project-category-filter-wrapper {
            padding: 100px 0 0 0;

            .tab-panel-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 66px;

                @media (max-width: "991px") {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 50px;
                }

                @media (max-width: "767px") {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                }

                .tab-panel-grid-item {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 22px;

                    .image-wrapper {
                        width: 430px;
                        height: 400px;

                        @media (max-width: "767px") {
                            width: 300px;
                            height: 330px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .grid-item-content {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 10px;

                        .item-title {
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 23.86px;
                            text-align: left;
                            color: #393184;
                        }

                        .item-location {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 19.09px;
                            text-align: left;
                            color: #A5A5A5;
                        }
                    }
                }
            }
        }

        .project-inspire-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 66px;
            padding: 100px 0 0 0;

            @media (max-width: "767px") {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
                padding: 70px 0 0 0;
            }

            .inspire-grid-item {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                gap: 20px;
                background-color: #f4f8ff;
                padding: 26px 34px;

                @media (max-width: "767px") {
                    align-items: center;
                    justify-content: center;
                }

                .grid-title {
                    font-size: 17px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #393184;

                    @media (max-width: "767px") {
                        text-align: center;
                    }
                }

                .grid-description {
                    max-width: 400px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #1e1e1e;

                    @media (max-width: "767px") {
                        text-align: justify;
                    }
                }
            }
        }

        .contact-us-wrapper {
            padding: 100px 0 0 0;
            text-align: center;
            margin-bottom: 100px;

            @media (max-width: "767px") {
                padding: 70px 0 0 0;
                margin-bottom: 70px;
            }

            button {
                text-align: center;
                background-color: #393184;
                padding: 14px 48px;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                border: none;
                cursor: pointer;
            }
        }
    }
}