.main-contact-us-wrapper {
    .contact-us-content-wrapper {
        .contact-section-title {
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            padding: 0 0 20px 0;
            color: #393184;
        }

        .contact-us-banner {
            .banner-content-wrapper {
                height: calc(100vh - 107px);
                position: relative;

                @media (max-width: "1500px") {
                    height: calc(100vh - 370px);
                }

                @media (max-width: "991px") {
                    height: calc(100vh - 340px);
                }

                @media (max-width: "767px") {
                    height: calc(100vh - 400px);
                }

                @media (max-width: "540px") {
                    height: calc(100vh - 650px);
                }

                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    background: #000;
                }

                .banner-text-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 38px;
                    flex-direction: column;
                    // max-width: 600px;
                    width: 100%;
                    transform: translate(-50%, -50%);

                    .banner-header-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 18px;
                        flex-direction: column;
                        text-align: center;
                        max-width: 842px;

                        @media (max-width: "991px") {
                            max-width: 720px;
                        }

                        @media (max-width: "767px") {
                            max-width: 380px;
                        }

                        .welcome-text {
                            color: #fff;
                            font-weight: 400;
                            font-size: 70px;
                            line-height: 80px;
                            text-align: center;

                            @media (max-width: "991px") {
                                font-size: 60px;
                            }

                            @media (max-width: "767px") {
                                font-size: 34px;
                                line-height: 44px;
                            }
                        }

                        p {
                            color: #fff;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24.84px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .contact-us-description-wrapper {
            text-align: center;
            max-width: 660px;
            margin: 0 auto;
            padding: 90px 0 0 0;

            @media (max-width: "540px") {
                padding: 50px 0 0 0;
            }

            span {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #393184;
                display: block;
                padding: 0 0 50px 0;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24.16px;
                letter-spacing: -0.011em;
                text-align: center;
                color: #a5a5a5;
                padding: 0 0 20px 0;
                max-width: 562px;
                margin: 0 auto;

                &:last-child {
                    padding: 0;
                }
            }
        }

        .contact-us-glass-icons-wrapper {
            text-align: center;
            padding: 90px 0 0 0;

            .glass-icons-grid-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                gap: 85px;

                @media (max-width: "767px") {
                    flex-wrap: wrap;
                }

                .glass-icons-grid-item {
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        display: block;
                        position: relative;
                        cursor: pointer;

                        background-repeat: no-repeat;
                        background-position: -120px -120px, 0 0;

                        background-image: -webkit-linear-gradient(top left,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0.2) 37%,
                                rgba(255, 255, 255, 0.8) 45%,
                                rgba(255, 255, 255, 0.0) 50%);
                        background-image: -moz-linear-gradient(0 0,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0.2) 37%,
                                rgba(255, 255, 255, 0.8) 45%,
                                rgba(255, 255, 255, 0.0) 50%);
                        background-image: -o-linear-gradient(0 0,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0.2) 37%,
                                rgba(255, 255, 255, 0.8) 45%,
                                rgba(255, 255, 255, 0.0) 50%);
                        background-image: linear-gradient(0 0,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0.2) 37%,
                                rgba(255, 255, 255, 0.8) 45%,
                                rgba(255, 255, 255, 0.0) 50%);

                        -moz-background-size: 250% 250%, 100% 100%;
                        background-size: 250% 250%, 100% 100%;

                        -webkit-transition: background-position 0s ease;
                        -moz-transition: background-position 0s ease;
                        -o-transition: background-position 0s ease;
                        transition: background-position 0s ease;

                        &:hover {
                            background-position: 0 0, 0 0;

                            -webkit-transition-duration: 0.5s;
                            -moz-transition-duration: 0.5s;
                            transition-duration: 0.5s;
                        }
                    }

                    .icon-text {
                        max-width: 136px;
                        margin: 0 auto;
                        color: #393184;
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 20.28px;
                        text-align: center;
                        padding: 16px 0 0 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .connect-with-us-wrapper {
            text-align: center;
            padding: 90px 0 0 0;

            .connect-with-us-description {
                max-width: 460px;
                margin: 0 auto;
                padding: 20px 0 56px 0;

                p {
                    color: #a5a5a5;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24.16px;
                    letter-spacing: -0.011em;
                    text-align: center;
                }
            }

            .connect-with-us-card {
                box-shadow: 0px 22px 48.9px 0px #3931844f;
                border: 4px solid;

                border-image-source: linear-gradient(295.16deg,
                        rgba(255, 255, 255, 0.8) 16.06%,
                        rgba(225, 223, 250, 0.8) 98.19%);
                background: #fff;
                padding: 26px;
                display: grid;
                grid-template-columns: 421px 1fr;
                gap: 62px;

                @media (max-width: "991px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media (max-width: "767px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .contact-information-wrapper {
                    background: #393184;
                    padding: 38px 46px 24px 42px;

                    .contact-information-header {
                        font-size: 27px;
                        font-weight: 700;
                        line-height: 32.21px;
                        text-align: left;
                        color: #ffffff;
                        padding: 0 0 25px 0;

                        @media (max-width: "767px") {
                            font-size: 20px;
                        }
                    }

                    .information-alignment {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        width: 100%;

                        .information-wrapper {
                            width: 100%;

                            .line-alignment {
                                width: 100%;
                                border: 1px solid #8c81df;
                                margin: 24px 0;
                            }

                            .contact-phone {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 26px;
                                width: 100%;

                                .phone-icon {
                                    width: 25px;
                                    height: 25px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                    }
                                }

                                .phone-information {
                                    text-align: left;

                                    .phone-title {
                                        font-size: 18px;
                                        font-weight: 400;
                                        line-height: 21.48px;
                                        color: #ffffff;
                                    }

                                    .phone-value {
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21.14px;
                                        letter-spacing: -0.011em;
                                        text-align: left;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    .follow-us-wrapper {
                        padding: 55px 0 0 0;

                        .follow-us-title {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24.16px;
                            letter-spacing: -0.011em;
                            text-align: left;
                            color: #fff;
                        }

                        .follow-us-icons-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 14px;
                            padding: 12px 0 0 0;
                        }
                    }
                }

                .contact-form-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 20px;

                    .field-alignment-wrapper {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 30px 50px;
                        width: 100%;

                        @media (max-width: "767px") {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }

                    .full-field-alignment-wrapper {
                        width: 100%;
                        margin-top: 30px;
                    }

                    button {
                        background: #393184;
                        color: #fff;
                        border: none;
                        padding: 12px 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 21.88px;
                        text-align: center;
                        max-width: 132px;
                        height: 46px;
                    }
                }
            }
        }

        .location-details-alignment {
            padding: 142px 0 100px 0;

            @media (max-width: "991px") {
                padding: 90px 0 100px 0;
            }

            @media (max-width: "767px") {
                padding: 90px 0 100px 0;
            }


            .location-details-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;

                @media (max-width: "991px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media (max-width: "767px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .location-details-grid-item {
                    .location-map {
                        width: 100%;
                        height: 252px;

                        iframe {
                            width: 100%;
                            height: 100%;
                            border: none;
                        }
                    }

                    .location-details-alignment {
                        padding: 38px 32px 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 42px;

                        .location-left {
                            display: flex;
                            align-items: center;
                            gap: 30px;

                            h6 {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24.16px;
                                letter-spacing: -0.011em;
                                text-align: left;
                                color: #393184;
                            }

                            p {
                                padding: 5px 0 0 0;

                                font-size: 12px;
                                font-weight: 400;
                                line-height: 18.12px;
                                letter-spacing: -0.011em;
                                text-align: left;
                                color: #7A70DA;
                            }
                        }

                        .location-right {
                            img {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}