.our-clients-section {
    padding: 118px 0 0 0;

    @media (max-width: 1279px) {
        padding: 90px 0 0 0;
    }

    @media (max-width: 767px) {
        padding: 50px 0 0 0;
    }

    .our-clients-wrapper {
        background: url("../../../assets/images/bg-map.png");
        width: 100%;
        height: 800px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;

        .our-client-details-alignment {
            padding: 60px 0 0 0;

            .india-map-alignment {
                width: 500px;
                height: 560px;
                margin: 0 auto;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                }

                .main-round-box {
                    width: 38px;
                    height: 38px;
                    background: linear-gradient(156.8deg, #ffffff 29.17%, #6fd1f4 101.67%);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 40%;
                    cursor: pointer;

                    .child-round-box {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        background: #393184;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: calc(100% - 24px);
                            height: calc(100% - 24px);
                            display: block;
                        }
                    }

                    &.viewBox {
                        .parent {
                            .hover-details {
                                transform: scale(1);
                                visibility: visible;
                                pointer-events: all;
                                touch-action: auto;
                            }
                        }
                    }

                    &:nth-child(2) {
                        width: 38px;
                        height: 38px;
                        top: 10%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(3) {
                        width: 50px;
                        height: 50px;
                        top: 40%;
                        left: 20%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(4) {
                        width: 50px;
                        height: 50px;
                        top: 35%;
                        left: 70%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(5) {
                        top: 55%;
                        left: 55%;
                    }

                    &:nth-child(6) {
                        width: 38px;
                        height: 38px;
                        top: unset;
                        bottom: 20%;
                        left: 44%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(7) {
                        width: 42px;
                        height: 42px;
                        top: 25%;
                        left: unset;
                        right: -10%;

                        .child-round-box {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &:nth-child(8) {
                        width: 50px;
                        height: 50px;
                        top: 44%;
                        left: 35%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(9) {
                        width: 50px;
                        height: 50px;
                        top: 40%;
                        left: 50%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(10) {
                        top: 30%;
                        left: 52%;
                    }

                    &:nth-child(11) {
                        width: 38px;
                        height: 38px;
                        top: unset;
                        bottom: 25%;
                        left: 50%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(12) {
                        width: 42px;
                        height: 42px;
                        top: 29%;
                        left: unset;
                        right: -3px;

                        .child-round-box {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &:nth-child(13) {
                        width: 38px;
                        height: 38px;
                        top: 20%;
                        left: 44%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(14) {
                        width: 50px;
                        height: 50px;
                        top: 60%;
                        left: 35%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(15) {
                        width: 50px;
                        height: 50px;
                        top: 50%;
                        left: 70%;

                        .child-round-box {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    &:nth-child(16) {
                        top: 62%;
                        left: 60%;
                    }

                    &:nth-child(17) {
                        width: 38px;
                        height: 38px;
                        top: unset;
                        bottom: 10%;
                        left: 44%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(18) {
                        width: 42px;
                        height: 42px;
                        top: 27%;
                        left: unset;
                        right: 20%;

                        .child-round-box {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &:nth-child(19) {
                        width: 38px;
                        height: 38px;
                        top: 30%;
                        left: 34%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    &:nth-child(20) {
                        width: 42px;
                        height: 42px;
                        top: 38%;
                        left: unset;
                        right: -6%;

                        .child-round-box {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &:nth-child(21) {
                        width: 38px;
                        height: 38px;
                        top: 12%;
                        left: 44%;

                        .child-round-box {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }

                .parent {
                    position: relative;

                    .hover-details {
                        transform: scale(0);
                        transform-origin: left;
                        visibility: hidden;
                        touch-action: none;
                        pointer-events: none;
                        border-radius: 0px;
                        overflow: hidden;
                        position: absolute;
                        cursor: pointer;
                        transition: all 0.4s ease-in-out;
                        left: 0;
                        top: 0;
                        width: 135px;
                        height: 110px;
                        background: linear-gradient(183.68deg,
                                rgba(241, 241, 255, 0.3) 53.11%,
                                rgba(212, 207, 255, 0.3) 92.73%);
                        border: 2px solid #fff;
                        z-index: 4;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: auto;
                            height: 100px;
                            display: block;
                            object-fit: cover;
                            border-radius: 0px;
                        }
                    }
                }
            }
        }
    }

    .mobile-our-clients-wrapper {
        .slick-slider {
            .slick-list {
                .slick-track {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: flex-start !important;
                    gap: 36px !important;

                    .slick-slide {

                        .client-logo-wrapper {
                            height: 100px !important;
                            width: 100px !important;

                            .logo-image {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }
                    }
                }
            }
        }

        .slick-dots {
            display: block;
            bottom: -20px !important;
            left: 0 !important;
            text-align: center !important;
            padding: 0 !important;
            width: 100%;
            position: relative;
            overflow: hidden;
            transition: transform 0.2s;
        }

        .slick-dots li button:before {
            font-size: 10px !important;
        }

        .slick-dots li button:after {
            content: unset;
        }

        .slick-dots li button:hover {
            &:hover {
                transform: unset;
            }
        }

        .slick-next {
            display: none !important;
        }

        .slick-prev {
            display: none !important;
        }
    }
}