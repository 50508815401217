.main-infrastructure-wrapper {
    .infrastructure-content-wrapper {
        .contact-section-title {
            font-size: 17px;
            font-weight: 400;
            line-height: 20.28px;
            padding: 0 0 20px 0;
            color: #393184;
        }

        .infrastructure-banner {
            background: rgba(0, 0, 0, 1);

            .banner-content-wrapper {
                height: calc(100vh - 107px);
                position: relative;

                @media (max-width: "1024px") {
                    height: calc(100vh - 618px);
                }

                @media (max-width: "991px") {
                    height: calc(100vh - 746px);
                }

                @media (max-width: "767px") {
                    height: calc(100vh - 670px);
                }

                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    opacity: 0.5;
                    position: absolute;
                    z-index: 2;
                }

                .banner-text-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 38px;
                    flex-direction: column;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    z-index: 3;

                    .banner-header-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 18px;
                        flex-direction: column;
                        text-align: center;
                        max-width: 960px;

                        @media (max-width: 991px) {
                            max-width: 700px;
                        }

                        @media (max-width: 767px) {
                            max-width: 700px;
                        }

                        @media (max-width: 540px) {
                            max-width: 600px;
                        }

                        .welcome-text {
                            font-size: 70px;
                            font-weight: 400;
                            line-height: 80.5px;
                            letter-spacing: -0.011em;
                            text-align: center;
                            color: #fff;


                            @media (max-width: 991px) {
                                font-size: 50px;
                                line-height: 60.5px;
                            }

                            @media (max-width: 767px) {
                                font-size: 60px;
                                line-height: 70.5px;
                            }

                            @media (max-width: 540px) {
                                font-size: 24px;
                                line-height: 36px;
                            }
                        }

                        p {
                            color: #fff;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24.84px;
                            text-align: center;
                            animation: pfadeinout 6s forwards;

                            @media (max-width: 540px) {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }

                        .fadeinout {
                            animation: fadeinout 6s forwards;
                        }

                        @keyframes fadeinout {
                            0% {
                                opacity: 0;
                            }

                            50% {
                                opacity: 1;
                            }

                            100% {
                                opacity: 0;
                            }
                        }

                        @keyframes pfadeinout {
                            0% {
                                opacity: 0;
                            }

                            50% {
                                opacity: 1;
                            }

                            100% {
                                opacity: 0;
                            }
                        }


                    }
                }
            }
        }


        .wrapper {
            width: 100%;
            row-gap: 50px;
            column-gap: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 104px 0;

            .card {
                width: 45%;
                height: 290px;
                border-radius: 15px;
                padding: 1.5rem;
                background: white;
                position: relative;
                display: flex;
                align-items: flex-end;
                transition: 0.4s ease-out;
                box-shadow: 0px 7px 10px rgba(black, 0.5);

                @media (max-width: 991px) {
                    width: 100%;
                }

                &:hover {
                    transform: translateY(20px);

                    &:before {
                        opacity: 1;
                    }

                    .info {
                        opacity: 1;
                        transform: translateY(0px);

                        h1 {

                            @media (max-width: 991px) {
                                font-size: 30px;
                            }

                            @media (max-width: 767px) {
                                font-size: 22px;
                            }
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                    background: rgba(black, 0.6);
                    z-index: 2;
                    transition: 0.5s;
                    opacity: 0;
                }


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 15px;
                }

                .info {
                    position: relative;
                    z-index: 3;
                    color: white;
                    opacity: 0;
                    transform: translateY(30px);
                    transition: 0.5s;

                    h1 {
                        margin: 0px;
                        margin-bottom: 10px;
                    }

                    .item-content-list {
                        padding: 0 0 0 22px;
                        margin: 0;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 24px;
                        max-width: 500px;

                        li {
                            font-family: Verdana;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24.16px;
                            letter-spacing: -0.011em;
                            text-align: left;
                            color: #fff;

                            &::marker {
                                color: #fff;
                            }
                        }
                    }

                    button {
                        padding: 0.6rem;
                        outline: none;
                        border: none;
                        border-radius: 3px;
                        background: white;
                        color: black;
                        font-weight: bold;
                        cursor: pointer;
                        transition: 0.4s ease;

                        &:hover {
                            background: dodgerblue;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}