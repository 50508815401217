.main-header-wrapper {
    padding: 20px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 99;
    background: #fff;

    .main-header-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 150px;

        @media (max-width: 1024px) {
            justify-content: space-between;
        }

        .main-logo-wrapper {
            height: 50px;
            width: 90px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .main-links-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;

            @media (max-width: 1024px) {
                display: none;
            }

            a {
                transition: 0.4s ease-in-out;
                text-decoration: none;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.45px;
                color: #a5a5a5;
                position: relative;

                &::before {
                    position: absolute;
                    bottom: -5px;
                    content: "";
                    width: 0px;
                    height: 2px;
                    background: #393184;
                    left: 0;
                    transition: 0.4s linear;
                }

                &:hover {
                    color: #393184;
                    font-weight: 700;

                    &::before {
                        width: 100%;
                        transition: 0.4s linear;
                    }
                }

                &.active {
                    color: #393184;
                    font-weight: 700;

                    &::before {
                        width: 100%;
                        transition: 0.4s linear;
                    }
                }
            }

            button {
                height: 56px;
                padding: 13px 25px;
                font-size: 14px;
                line-height: 17px;
                font-weight: 700;
                text-align: center;
                border: none;
                background: #393184;
                font-size: 18px;
                line-height: 21px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
            }
        }


        .main-mobile-menu {
            display: none;

            @media (max-width: 1024px) {
                display: block;
            }
        }
    }
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9;
    top: 90px;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;

    .menu-content-alignment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .icon-alignment {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin-top: 20px;
            margin-right: 20px;

            .closebtn {
                font-size: 40px;
                color: #fff;
            }
        }

        .overlay-content {
            position: relative;
            top: 25%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            flex-direction: column;

            a {
                padding: 8px;
                text-decoration: none;
                font-size: 26px;
                color: #fff;
                display: block;
                transition: 0.3s;
            }
        }
    }
}