.footer-alignment {
    background-color: #ECEAFF;
    position: absolute;
    width: 100%;

    .main-footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        padding: 30px 0;

        @media (max-width: 991px) {
            flex-direction: column;
            justify-content: center;
            gap: 36px;
            width: 100%;
        }

        .first-child-alignment {
            .logo-alignment {
                height: 50px;
                width: 90px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                max-width: 468px;
                padding: 28px 0 0 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 21.14px;
                letter-spacing: -0.011em;
                text-align: left;
                color: #1e1e1e;
            }

            .footer-button {
                padding: 28px 0 0 0;
                max-width: 140px;
                height: 44px;

                button {
                    width: 100%;
                    height: 100%;
                    background: #393184;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.01px;
                    text-align: center;
                    color: #ffffff;
                    border: none;
                    cursor: pointer;
                }
            }

            .social-alignment {
                padding: 24px 0 0 0;
                display: flex;
                align-items: center;
                gap: 14px;

                @media (max-width: 991px) {
                    padding: 0;
                }

                .social-icon-box {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    background: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            @media (max-width: 991px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }
        }

        .footer-right-side-alignment {
            display: flex;
            align-items: flex-start;
            gap: 62px;

            @media (max-width: 991px) {
                width: 100%;
                justify-content: space-evenly;
                flex-wrap: wrap;
            }

            @media (max-width: 767px) {
                justify-content: flex-start;
                gap: 70px;
            }

            .footer-content-alignment {
                &.second-child {
                    padding: 32px 0 0 0;
                }

                h6 {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.01px;
                    color: #000000;
                    padding: 0 0 18px 0;
                    cursor: pointer;
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 33.32px;
                    letter-spacing: -0.011em;
                    color: #1e1e1e;
                    cursor: pointer;
                }
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid #39318433;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 30px 0;
        gap: 68px;

        @media (max-width: "767px") {
            flex-direction: column;
            gap: 5px;
            justify-content: center;
        }

        a {
            font-size: 14px;
            font-weight: 500;
            line-height: 33.32px;
            letter-spacing: -0.011em;
            text-align: left;
            color: #1e1e1e;
            cursor: pointer;
            text-decoration: none;
        }
    }
}