.main-about-us-wrapper {
    .about-us-content-wrapper {
        .about-section-title {
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            padding: 0 0 35px 0;
            color: #393184;
        }

        .about-us-banner-wrapper {
            .banner-content-wrapper {
                height: calc(100vh - 107px);
                position: relative;

                @media (max-width: "1023px") {
                    height: 400px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(27 35 84 / 53%);
                }

                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }

                .banner-text-wrapper {
                    position: absolute;
                    top: 54%;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 38px;
                    flex-direction: column;
                    width: 100%;
                    transform: translate(-50%, -50%);

                    .banner-header-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 18px;
                        flex-direction: column;
                        text-align: center;

                        .welcome-text {
                            color: #fff;
                            font-weight: 400;
                            font-size: 70px;
                            line-height: 80px;
                            text-align: center;

                            @media (max-width: "1023px") {
                                font-size: 50px;
                                line-height: 60px;
                            }

                            @media (max-width: "767px") {
                                font-size: 32px;
                                line-height: 38px;
                            }
                        }

                        p {
                            color: #fff;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 26.84px;
                            text-align: center;

                            @media (max-width: "767px") {
                                font-size: 16px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }

        .about-us-description {
            text-align: center;
            max-width: 575px;
            margin: 0 auto;
            padding: 90px 0 0 0;

            @media (max-width: "767px") {
                padding: 50px 0 0 0;
            }

            p {
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #a5a5a5;

                @media (max-width: "767px") {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        .our-story-wrapper {
            padding: 90px 0 0 0;
            max-width: 1114px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 498px 1fr;
            gap: 100px;

            @media (max-width: 1279px) {
                grid-template-columns: repeat(1, 1fr);
                align-items: center;
                justify-content: center;
            }

            @media (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                align-items: center;
                justify-content: center;
                gap: 50px;
            }

            .about-us-details {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #1e1e1e;

                @media (max-width: 1279px) {
                    text-align: center;
                }

                @media (max-width: 991px) {
                    text-align: center;
                }

                @media (max-width: "767px") {
                    font-size: 14px;
                    line-height: 21px;
                }

                h3 {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 36px;
                    text-align: left;
                    color: #393184;
                    padding: 0 0 24px 0;

                    @media (max-width: 1279px) {
                        text-align: center;
                    }

                    @media (max-width: 991px) {
                        text-align: center;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24.16px;
                    letter-spacing: -0.011em;
                    color: #a5a5a5;
                    padding: 0 0 10px 0;
                }
            }

            .about-us-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 50px;

                @media (max-width: 1279px) {
                    gap: 30px;
                    align-items: center;
                    justify-content: center;
                }

                @media (max-width: 991px) {
                    gap: 30px;
                    align-items: center;
                    justify-content: center;
                }

                @media (max-width: 767px) {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .about-us-grid-item {
                    display: flex;
                    align-items: center;
                    gap: 30px;

                    @media (max-width: 991px) {
                        justify-content: flex-start;
                    }

                    @media (max-width: 1279px) {
                        justify-content: flex-start;
                    }

                    .content-alignment {
                        .content-title {
                            font-size: 27px;
                            font-weight: 600;
                            line-height: 40.77px;
                            letter-spacing: -0.011em;
                            text-align: left;
                            color: #393184;
                        }

                        .content-description {
                            font-size: 14.52px;
                            font-weight: 500;
                            line-height: 18.73px;
                            letter-spacing: -0.011em;
                            text-align: left;
                            color: #a5a5a5;
                            padding: 12px 0 0 0;
                        }
                    }
                }
            }
        }

        .our-history-section {
            padding: 22px 0 0 0;
            max-width: 1100px;
            margin: 0 auto;

            .our-history-alignment {
                .ouur-history-heading {
                    padding: 48px 0;
                    text-align: center;

                    h2 {
                        font-size: 28px;
                        font-weight: 500;
                        line-height: 36px;
                        color: #393184;
                    }
                }

                .our-history-grid {
                    display: grid;
                    grid-template-columns: 134px 1fr;
                    gap: 80px;

                    @media (max-width: "767px") {
                        grid-template-columns: 1fr;
                        gap: 20px;
                    }

                    .our-history-grid-item {
                        .left-side-alignment {
                            .child-left-side-details-alignment {
                                position: relative;

                                .child-details-alignment {
                                    display: grid;
                                    grid-template-columns: 10px 1fr;
                                    gap: 18px;
                                    min-height: 60px;
                                    align-items: center;
                                    // margin: 0 0 18px 0;
                                    cursor: pointer;
                                    transition: 0.4s ease-in-out;

                                    .dot-alignment {
                                        width: 8px;
                                        height: 8px;
                                        background: #7a70da;
                                        border-radius: 50%;
                                        position: relative;
                                        margin: 0 auto;
                                        transition: 0.4s ease-in-out;
                                    }

                                    .year-alignment {
                                        transition: 0.4s ease-in-out;

                                        span {
                                            font-size: 16px;
                                            font-weight: 400;
                                            line-height: 24.16px;
                                            letter-spacing: -0.011em;
                                            color: #7a70da;
                                        }
                                    }
                                }

                                .active-child-details {
                                    .dot-alignment {
                                        width: 100%;
                                        height: 10px;
                                        background: #393184;
                                        transition: 0.4s ease-in-out;
                                    }

                                    .year-alignment {
                                        transition: 0.4s ease-in-out;

                                        span {
                                            font-size: 41px;
                                            line-height: 61px;
                                            color: #393184;
                                            transition: 0.3s ease-in-out;

                                            @media (max-width: 991px) {
                                                font-size: 31px;
                                            }

                                            @media (max-width: 1279px) {
                                                font-size: 31px;
                                            }
                                        }
                                    }
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    height: calc(100% - 56px);
                                    left: 4px;
                                    top: 26px;
                                    background: #7a70da;
                                }
                            }


                            @media (max-width: "767px") {
                                display: none;
                            }
                        }

                        .right-side-alignment {
                            h4 {
                                font-size: 41px;
                                font-weight: 400;
                                line-height: 61.91px;
                                letter-spacing: -0.011em;
                                color: #393184;
                                animation: fadeinout 5s infinite;
                                animation-duration: 5s;

                                @media (max-width: 991px) {
                                    font-size: 31px;
                                }

                                @media (max-width: 1279px) {
                                    font-size: 31px;
                                }
                            }

                            p {
                                padding: 9px 0 0 0;
                                font-family: Verdana;
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 24.16px;
                                letter-spacing: -0.011em;
                                color: #393184;
                                animation: fadeinout 5s infinite;
                                animation-duration: 5s;

                                @media (max-width: 991px) {
                                    font-size: 18px;
                                }

                                @media (max-width: 1279px) {
                                    font-size: 18px;
                                }
                            }

                            .history-img-alignment {
                                width: 100%;
                                height: 468px;
                                margin: 40px 0 0 0;
                                animation: fadeinout 5s infinite;
                                animation-duration: 5s;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    object-fit: cover;
                                }
                            }

                            @keyframes fadeinout {
                                0% {
                                    opacity: 0;
                                }

                                25% {
                                    opacity: 0.3;
                                }

                                50% {
                                    opacity: 0.6;
                                }

                                75% {
                                    opacity: 1;
                                }

                                100% {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .glass-processing-wrapper {
            padding: 76px 0 0 0;

            .processing-steps-wrapper {
                background: #eceaff;
                padding: 46px 84px;

                @media (max-width: "991px") {
                    padding: 30px;
                }

                @media (max-width: "767px") {
                    padding: 12px;
                }

                .processing-steps-grid {
                    display: grid;
                    grid-template-columns: 36px 1fr;
                    gap: 102px;

                    @media (max-width: "991px") {
                        gap: 30px;
                    }

                    @media (max-width: "767px") {
                        grid-template-columns: 1fr;
                        gap: 30px;
                    }

                    .mobile-view-alignment {
                        @media (max-width: "767px") {
                            display: none;
                        }
                    }

                    .stepper-alignment-number {
                        position: relative;
                        min-height: 114px;


                        @media (max-width: "991px") {
                            min-height: 120px;
                        }

                        @media (max-width: "767px") {
                            min-height: auto;
                        }

                        .step-round-alignment {
                            width: 36px;
                            min-width: 36px;
                            max-width: 36px;
                            height: 36px;
                            background: linear-gradient(147.07deg, #ffffff -0.14%, #8dbaff 105.15%);
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .child-box-alignmet {
                                width: 30px;
                                max-width: 30px;
                                min-width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #393184;
                                border-radius: 50%;

                                p {
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 25.59px;
                                    text-align: left;
                                    color: #fff;
                                }
                            }
                        }

                        .steper-border {
                            position: absolute;
                            right: 50%;
                            border-right: 1px dashed #8c81dc;
                            height: 78px;
                            transform: translateX(-50%);
                            top: 37px;

                            @media (max-width: "767px") {
                                display: none;
                            }
                        }
                    }

                    .child-grid-alignment {
                        display: grid;
                        grid-template-columns: 276px 1fr;
                        gap: 94px;
                        min-height: 114px;

                        @media (max-width: "991px") {
                            min-height: 120px;
                            gap: 30px;
                        }

                        @media (max-width: "767px") {
                            grid-template-columns: 1fr;
                            gap: 20px;
                            min-height: auto;
                            padding: 0 0 40px 0;
                        }

                        .step-title {
                            font-size: 17px;
                            font-weight: 400;
                            line-height: 24px;
                            color: #393184;
                        }

                        .step-description {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24.16px;
                            letter-spacing: -0.011em;
                            text-align: left;
                            color: #7a70da;
                        }
                    }
                }
            }
        }

        .our-values-wrapper {
            padding: 100px 0 0 0;
            max-width: 1122px;
            margin: 0 auto;

            @media (max-width: "767px") {
                padding: 60px 0 0 0;
            }

            .values-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 46px 50px;

                @media (max-width: 1279px) {
                    gap: 20px;
                }

                @media (max-width: 991px) {
                    gap: 20px;
                }

                @media (max-width: "767px") {
                    grid-template-columns: 1fr;
                }

                .value-grid-item {
                    background: #eceaff;
                    padding: 26px 34px;

                    .grid-item-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 34px;


                        @media (max-width: "991px") {
                            flex-wrap: wrap;
                        }

                        .item-content {
                            .grid-item-title {
                                font-size: 17px;
                                font-weight: 400;
                                line-height: 23.8px;
                                text-align: left;
                                color: #393184;
                                padding: 0 0 16px 0;
                            }

                            .grid-item-description {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24.16px;
                                letter-spacing: -0.011em;
                                text-align: justify;
                                color: #7a70da;
                            }
                        }
                    }
                }
            }

            .four-ps-grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 92px;

                @media (max-width: "767px") {
                    grid-template-columns: 1fr;
                    gap: 40px;
                }

                .ps-grid-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 18px;

                    .ps-grid-item-title {
                        font-family: Verdana;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24.16px;
                        letter-spacing: -0.011em;
                        text-align: center;
                        color: #7a70da;
                    }
                }
            }
        }

        .social-proof-wrapper {
            padding: 100px 0 0 0;

            .social-proof-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 50px;

                @media (max-width: 1279px) {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px;
                }

                @media (max-width: 991px) {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px;
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                }

                .social-grid-item {
                    .certificate-wrapper {
                        height: 280px;
                        background-color: #eceaff;
                        border: none;
                        border-radius: 10px;
                        padding: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: contain;
                            cursor: pointer;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24.16px;
                        letter-spacing: -0.011em;
                        text-align: center;
                        color: #a5a5a5;
                        padding: 20px 0 0 0;
                    }
                }
            }
        }

        .connect-with-us-wrapper {
            padding: 100px 0 0 0;
            margin-bottom: 100px;
            text-align: center;

            h3 {
                font-size: 28px;
                font-weight: 500;
                line-height: 36px;
                text-align: center;
                color: #393184;
                padding: 0 0 18px 0;
            }

            .connect-with-us-description {
                font-size: 16px;
                font-weight: 400;
                line-height: 24.16px;
                letter-spacing: -0.011em;
                text-align: center;
                color: #a5a5a5;
                max-width: 545px;
                padding: 0 0 35px 0;
                margin: 0 auto;
            }

            .contact-us-button {
                color: #fff;
                background-color: #393184;
                padding: 14px 48px;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                text-align: center;
                border: none;
                cursor: pointer;
                max-width: 180px;
            }
        }
    }

    .dialog-image {
        max-height: 580px;
    }
}