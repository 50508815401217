.glass-configuration-section {
    padding: 90px 0 0 0;

    .glass-configuration-alignment {
        h2 {
            max-width: 610px;
            margin: 0 auto;
            font-size: 20px;
            font-weight: 500;
            line-height: 26.84px;
            letter-spacing: -0.011em;
            color: #1e1e1e;
            text-align: center;
        }
    }

    .glass-configuration-all-details-alignment {
        padding: 116px 0 0 0;

        @media (max-width: 991px) {
            padding: 70px 0 0 0;
        }

        @media (max-width: 540px) {
            padding: 70px 0 0 0;
        }

        .glass-configuration-grid {
            display: grid;
            grid-template-columns: 524px 1fr;
            gap: 100px;
            align-items: flex-start;

            @media (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 50px;
            }
            @media (max-width: 540px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 50px;
            }

            .glass-configuration-left-side {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 16px 18px;
                padding: 26px 0 0 0;

                .glass-configuration-box {
                    width: 162px;
                    height: 108px;
                    background: #f0f0ff;
                }
            }

            .glass-configuration-right-side {
                .right-grid {
                    display: grid;
                    grid-template-columns: 28px 1fr;
                    gap: 52px;
                    align-items: center;
                    margin: 0 0 42px 0;

                    .main-round-box {
                        background: linear-gradient(147.07deg, #ffffff -0.14%, #bbb3fe 105.15%);
                        width: 100%;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 1px;
                            background: #A79EFF;
                            height: 65px;
                            top: 30px;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        .round-box {
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #393184;

                            span {
                                font-size: 14.93px;
                                font-weight: 700;
                                line-height: 19.91px;
                                color: #ffffff;
                            }
                        }
                    }

                    .main-round-text {
                        p {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22.4px;
                            text-align: left;
                            color: #393184;
                        }
                    }

                    &:last-child {
                        margin: 0;

                        .main-round-box {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}